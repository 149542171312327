<template>
    <div class="error-container position-absolute">
        <div class="error-wrapper">
            <img class="error-image" src="~@/assets/images/nofound.svg" />
            <div class="notfound-content-text">{{ $t('error-handler.cool') }}</div>
            <div class="notfound-content-text">{{ $t('error-handler.about-page-that-we-dont-know') }}</div>
            <div class="notfound-content-text">{{ $t('error-handler.press-back-button') }}</div>
            <router-link to="/">{{ $t('error-handler.or-click-home-button') }}</router-link>
        </div>
    </div>
</template>

<style scoped>
.error-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.error-wrapper {
    height: 100%;
    text-align: center;
}
.notfound-content-text {
    color: #303030;
    font-size: 1.5rem;
}
img {
    margin-bottom: 16px;
}
a {
    color: #007bff;
    font-size: 1.5rem;
    text-decoration: underline !important;
}
a:hover {
    color: #00818d;
    text-decoration: underline !important;
}

@media screen and (max-width: 991px) {
    .error-container {
        width: 100%;
    }
    .error-image {
        max-width: 60%;
    }
    .notfound-content-text {
        color: #303030;
        font-size: 1.25rem;
    }
    a {
        color: #007bff;
        font-size: 1.25rem;
    }
}
</style>